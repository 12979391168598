<template>
  <div class="order-center">
    <div class="title_box">
      <div class="title_txt">订单中心</div>
      <div class="refresh">
        <i class="icon"></i>
        <div class="refresh_txt" @click="getOrderList">刷新</div>
      </div>
    </div>

    <div
      v-show="orderlist.length != 0"
      class="order_data"
      v-for="(item, index) in orderlist"
      :key="index"
    >
      <div class="data_top_box">
        <div class="serial_box">
          订单编号:
          <span class="color_txt"> {{ item.orderNumber }} </span>
        </div>
        <div class="place_date_box">下单时间</div>
        <div class="number_box">数量</div>
        <div class="money_box">金额</div>
        <div class="txt">{{ item.payStatus == 1 ? "未完成" : "已完成" }}</div>
      </div>
      <div class="data_bottom_box">
        <div class="list">
          <div
            class="item_box_left"
            v-for="(item1, index) in item.productInfos"
            :key="index"
          >
            <div class="img_name_box">
              <img
                class="item_img"
                :src="
                  item1.productFiles
                    ? $store.state.onlineBasePath + item1.productFiles
                    : require('assets/images/no-shop-img80.png')
                "
                alt=""
              />

              <!-- <div>{{ $forceUpdate(item1.productFiles["fileUrl"]) }}</div> -->
              <div class="item_name">
                {{ item1.productName }} <br />
                支付类型:{{ item.payType == 1 ? "微信" : "支付宝" }}
              </div>
            </div>

            <div class="item_date">{{ item.createTime.substring(0, 10) }}</div>
            <div class="item_sum">{{ item1.productNum }}</div>
            <div class="item_money">¥ {{ item1.productPrices }}</div>
          </div>
        </div>

        <div class="item_box_right">
          <div class="order_money">
            ¥ {{ item.payPrice == null ? item.orderPrice : item.payPrice }}
          </div>
          <div
            class="order_details"
            @click="
              $router.push({
                path: 'orderDetails',
                query: {
                  item: JSON.stringify(item),
                  page: page,
                },
              })
            "
          >
            [查看订单详情]
          </div>
          <div class="order_delete" @click="prompt(item.orderNumber)">删除</div>
          <!-- <div class="order_collection">收藏</div> -->
          <div class="order_comments" @click="download(item)">下载</div>
        </div>
      </div>
    </div>

    <div v-show="orderlist.length != 0" class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-size="limit"
        :current-page="page"
        @prev-click="lastPageChange"
        @next-click="nextPageChange"
        @current-change="currentChange"
        :total="total"
      >
      </el-pagination>
    </div>

    <div v-show="orderlist.length == 0" class="noOrder">
      <img :src="require('assets/images/no-shop.png')" alt="" />
      暂无订单
    </div>
  </div>
</template>

<script>
import { orderPage, orderDelete } from "api/copyrightService";
export default {
  name: "OrderCenter",
  data() {
    return {
      //页码
      page: this.$route.params.page ? this.$route.params.page : 1,
      //一页10条数据
      limit: 10,
      //总条数
      total: 0,
      // 订单列表
      orderlist: [],
      // 暂无订单
      isShowImg: false,
    };
  },
  methods: {
    // 获取订单列表
    async getOrderList() {
      const data = {
        page: this.page,
        limit: this.limit,
      };

      const res = await orderPage(data);

      if (res.code === 200) {
        this.orderlist = res.data.data;
        this.total = res.data.total;
        if (this.orderlist.length === 0) {
          this.isShowImg = true;
        }
      }
    },
    download(item) {
      if (!item.enclosure) {
        this.$message("暂无资源下载");
        return;
      }

      window.open(item.enclosure + "?attname=" + item.productName);
    },
    // 删除订单
    async removeOrder(orderNumber) {
      const data = {
        orderNumber,
      };

      const res = await orderDelete(data);
      if (res.code !== 200) {
        return this.$message({
          type: "error",
          message: res.message,
        });
      }

      this.getOrderList();
      this.$message({
        type: "success",
        message: "删除订单成功",
      });
    },

    prompt(orderNumber) {
      this.$confirm("此操作将永久删除该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.removeOrder(orderNumber);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //  上一页
    lastPageChange(val) {
      this.page = val;
    },

    // 下一页
    nextPageChange(val) {
      this.page = val;
    },

    // page改变触发
    currentChange(cur) {
      this.page = cur;
      this.getOrderList();
    },
  },

  created() {
    this.getOrderList();
  },
};
</script>

<style lang="scss" scoped>
.order-center {
  .title_box {
    padding: 0 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title_txt {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }

    .refresh {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      cursor: pointer;
      .icon {
        width: 15px;
        height: 13px;
        @include backgroundGroup("../../../../assets/images/refresh-gray.png");
        margin-right: 10px;
      }
      .refresh_txt {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
    }
  }

  .order_data {
    .data_top_box {
      display: flex;
      background-color: #f8f8f8;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 800;
      padding: 10px 0;

      .serial_box {
        padding: 0 0 0 20px;
        flex: 25%;
        .color_txt {
          color: #ff6900;
        }
      }

      .place_date_box {
        flex: 20%;
        text-align: center;
      }

      .number_box {
        flex: 8%;
        padding-left: 21px;
        box-sizing: border-box;
      }

      .money_box {
        flex: 10%;
        text-align: center;
      }

      .txt {
        flex: 20%;
        text-align: center;
      }
    }

    .data_bottom_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 20px;

      .list {
        flex: 1;
      }
      .item_box_left {
        padding: 20px 0;
        flex: 1;
        display: flex;
        border-bottom: 1px solid #ccc;

        &:last-child {
          border-bottom: none;
        }

        .img_name_box {
          flex: 28%;
          display: flex;
          flex-direction: row;
          .item_img {
            width: 80px;
            height: 80px;
            margin-right: 20px;
          }
          .item_name {
            line-height: 30px;
          }
        }

        .item_date {
          flex: 12%;
          text-align: center;
        }

        .item_sum {
          flex: 2%;
          text-align: center;
        }

        .item_money {
          flex: 11%;
          text-align: center;
        }
      }

      .item_box_right {
        // width: 205px;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .order_money {
          margin: 5px 0;
          color: #eb441e;
          font-weight: 800;
        }

        .order_details {
          margin: 5px 0;
          color: #ff6900;
          cursor: pointer;
        }

        .order_delete,
        .order_collection,
        .order_comments {
          cursor: pointer;
          margin: 5px 0;
        }
      }
    }
  }

  .pagination {
    margin: 40px;

    .el-pagination {
      text-align: center;
    }
  }

  .noOrder {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 220px 0;

    img {
      margin-bottom: 20px;
    }
  }
}
</style>
